import React from 'react';

const PrivacyPolicy = () => {
    return (
        <div style={{ padding: '20px', fontFamily: 'Arial, sans-serif' }}>
            <h1>Privacy Policy</h1>
            <p>Last Updated: [21-09-2024]</p>

            <h2>1. Information We Collect</h2>
            <h3>Personal Information</h3>
            <p>
                We may collect personal information that you provide to us directly, including but not limited to:
            </p>
            <ul>
                <li>Name</li>
                <li>Email address</li>
                <li>Phone number</li>
                <li>Billing information</li>
                <li>Any other information you choose to provide</li>
            </ul>

            <h3>Usage Data</h3>
            <p>
                We may also collect information that your device sends whenever you use our App ("Usage Data"). This may include information such as your device's Internet Protocol address (e.g., IP address), browser type, browser version, and the pages of our App that you visit.
            </p>

            <h2>2. How We Use Your Information</h2>
            <p>
                We use the information we collect for various purposes, including:
            </p>
            <ul>
                <li>To provide and maintain our App</li>
                <li>To notify you about changes to our App</li>
                <li>To allow you to participate in interactive features of our App</li>
                <li>To provide customer support</li>
                <li>To gather analysis or valuable information so that we can improve our App</li>
            </ul>
        </div>
    );
};

export default PrivacyPolicy;
